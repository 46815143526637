import { useEffect, useRef, useState } from "react"

export const useVideo = (ref, source, togglePlayback, classes) => {
  const [firstPlay, setFirstPlay] = useState(false)
  const video = useRef()

  useEffect(() => {
    if (ref.current && source) {
      const vid = document.createElement("video")
      vid.id = "main"
      vid.className += classes
      vid.autoplay = true
      vid.loop = true
      vid.defaultMuted = true // fixes autoplay in chrome
      vid.muted = true // fixes autoplay in chrome
      vid.setAttribute("playsinline", "true") // fixes autoplay in webkit (ie. mobile safari)

      const sourceEl = document.createElement("source")
      sourceEl.src = source
      sourceEl.type = "video/mp4"
      vid.appendChild(sourceEl)
      ref.current.appendChild(vid)
      video.current = vid
    }
  }, [source])

  useEffect(() => {
    if (togglePlayback && !firstPlay) setFirstPlay(true)

    if (video.current && firstPlay) {
      if (togglePlayback) {
        video.current.play()
      } else {
        video.current.pause()
      }
    }
  }, [togglePlayback, video, firstPlay])

  return {
    video,
  }
}
