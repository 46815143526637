import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"


// Components
import SEO from "../components/SEO"
import IconTabs from "../modules/IconTabs/IconTabs"
import CenterTitle from "../components/CenterTitle"

// Helpers & Content
import { howToTierContent } from "../utils/content/howToTierContent"
import { fireEvent } from "../utils/helpers"

// markup
const HowToTierPage = () => {
  const { seo, centerTitle, iconTabs } = howToTierContent
  const { formatMessage } = useIntl()

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  return (
    <div>
      <SEO title={formatMessage({ id: seo.metaTitle })} description={formatMessage({ id: seo.metaDescription })} />
      <CenterTitle content={centerTitle} />
      <IconTabs content={iconTabs} />
    </div>
  )
}

export default HowToTierPage
