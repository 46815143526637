import videoPh from "../../assets/images/howToTier/Video3loop.mp4"
import scooterIcon from "../../assets/images/howToTier/scooter/illuSmall.svg"
import mopedIcon from "../../assets/images/howToTier/moped/illuSmall.svg"
import scooterFirst from "../../assets/images/howToTier/step1.svg"
import mopedFirst from "../../assets/images/howToTier/step1.svg"
import scooterTwo from "../../assets/images/howToTier/scooter/step2.svg"
import mopedTwo from "../../assets/images/howToTier/moped/step2.svg"
import scooterThree from "../../assets/images/howToTier/scooter/step3.svg"
import mopedThree from "../../assets/images/howToTier/moped/step3.svg"
import scooterFour from "../../assets/images/howToTier/scooter/step4.svg"
import mopedFour from "../../assets/images/howToTier/moped/step4.svg"
import scooterFive from "../../assets/images/howToTier/scooter/step5.svg"
import mopedFive from "../../assets/images/howToTier/moped/step5.svg"
import scooterSix from "../../assets/images/howToTier/scooter/step6.svg"
import mopedSix from "../../assets/images/howToTier/moped/step6.svg"
import scooterSeven from "../../assets/images/howToTier/scooter/step7.svg"
import mopedSeven from "../../assets/images/howToTier/moped/step7.svg"
import scooterEight from "../../assets/images/howToTier/scooter/step8.svg"
import mopedEight from "../../assets/images/howToTier/moped/step8.svg"
import mopedNine from "../../assets/images/howToTier/moped/step9.svg"

export const howToTierContent = {
  seo: {
    metaTitle: "metaTitleHowTierWorks",
    metaDescription: "metaDescriptionHowTierWorks",
  },
  centerTitle: {
    header: "howToCenterTitleHeadline",
    text: "howToCenterTitleDescription",
    video: videoPh,
  },
  iconTabs: {
    tabs: [
      {
        title: "howToIconTabsOneTitle",
        icon: scooterIcon,
        cta: "howToIconTabsOneCta",
        ctaLink: "https://www.youtube.com/watch?v=pURTpH_-R6M",
        background: "bg-green-background",
        tabColor: "bg-green-light",
        steps: [
          {
            icon: scooterFirst,
            title: "howToStep1",
            description: "howToIconTabsOneStepOne",
          },
          {
            icon: scooterTwo,
            title: "howToStep2",
            description: "howToIconTabsOneStepTwo",
          },
          {
            icon: scooterThree,
            title: "howToStep3",
            description: "howToIconTabsOneStepThree",
          },
          {
            icon: scooterFour,
            title: "howToStep4",
            description: "howToIconTabsOneStepFour",
          },
          {
            icon: scooterFive,
            title: "howToStep5",
            description: "howToIconTabsOneStepFive",
          },
          {
            icon: scooterSix,
            title: "howToStep6",
            description: "howToIconTabsOneStepSix",
          },
          {
            icon: scooterSeven,
            title: "howToStep7",
            description: "howToIconTabsOneStepSeven",
          },
          {
            icon: scooterEight,
            title: "howToStep8",
            description: "howToIconTabsOneStepEight",
          },
        ],
      },
      {
        title: "howToIconTabsTwoTitle",
        icon: mopedIcon,
        cta: "howToIconTabsTwoCta",
        ctaLink: "https://www.youtube.com/watch?v=yAmDHRp6bhM",
        background: "bg-green-background",
        tabColor: "bg-green-light",
        steps: [
          {
            icon: mopedFirst,
            title: "howToStep1",
            description: "howToIconTabsTwoStepOne",
          },
          {
            icon: mopedTwo,
            title: "howToStep2",
            description: "howToIconTabsTwoStepTwo",
          },
          {
            icon: mopedThree,
            title: "howToStep3",
            description: "howToIconTabsTwoStepThree",
          },
          {
            icon: mopedFour,
            title: "howToStep4",
            description: "howToIconTabsTwoStepFour",
          },
          {
            icon: mopedFive,
            title: "howToStep5",
            description: "howToIconTabsTwoStepFive",
          },
          {
            icon: mopedSix,
            title: "howToStep6",
            description: "howToIconTabsTwoStepSix",
          },
          {
            icon: mopedSeven,
            title: "howToStep7",
            description: "howToIconTabsTwoStepSeven",
          },
          {
            icon: mopedEight,
            title: "howToStep8",
            description: "howToIconTabsTwoStepEight",
          },
          {
            icon: mopedNine,
            title: "howToStep9",
            description: "howToIconTabsTwoStepNine",
          },
        ],
      },
    ],
  },
}
